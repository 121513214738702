<template>
  <transition appear>
    <div class="mt-10 w-10/12 m-auto">
      <div class="flex gap-10 justify-around items-center w-auto m-auto">
        <div class="flex flex-col gap-y-3">
          <horizontal-button-tile
            icon="person_add"
            title="New Candidates Profile"
            @click="showCreateUser = true"
          />
          <horizontal-button-tile
            icon="person"
            title="View All Candidates Profile"
            @click="showAllProfilesModal = true"
          />
        </div>
        <dropdown-search-filter
          class="w-3/4"
          title="Vacancy Search"
          :mainFilter="mainFilter2"
          :secondaryFilters="secondaryFilters"
          :inputPlaceHolder="'please input'"
          @search="searchFunc2"
          @reset="fetchAllHiringRequests('approved', 1)"
          @selectedMainFilter="mainFilterSelected"
        />
      </div>
      <div class="w-auto mx-auto">
        <div class="flex-1 flex-col justify-around items-center mt-5">
          <!-- OPEN Vacancies TITLE -->
          <div
            v-if="!isLoading"
            class="bg-lightGrey w-auto rounded-xl shadow-md"
          >
            <div class="pl-4 py-4">
              <h2 class="text-grey font-bold">Open Vacancies</h2>
            </div>
            <!-- <template v-if="!isLoading"> -->
            <template v-if="getApprovedHiringRequest">
              <table
                :key="refreshTable"
                class="text-center w-full text-grey my-2"
              >
                <dynamic-table class="text-teal" :showTitle="false" title="">
                  <open-vacancies-table-item
                    v-if="!isLoading"
                    :data="getApprovedHiringRequest?.data"
                    :link_2="'re-manage-vacancy'"
                    :myParameter_2="'manageVacancy'"
                  >
                  </open-vacancies-table-item>
                </dynamic-table>
              </table>
            </template>
            <no-data-found v-else />
          </div>
          <div
            v-if="getApprovedHiringRequest?.total > 0"
            class="flex justify-center my-5"
          >
            <el-pagination
              background
              layout="pager"
              :total="getApprovedHiringRequest?.total"
              @current-change="change"
            >
            </el-pagination>
          </div>
          <template v-else>
            <table-skeleton />
          </template>
        </div>
      </div>
      <base-modal
        v-model:show="showCreateUser"
        @confirm="submit"
        :showCancel="false"
        :showConfirm="false"
      >
        <template #title>Create New User Profile</template>
        <template #content>
          <div class="p-5">
            <v-select
              class="filterDropDown cursor-pointer"
              placeholder="Please Select the Hiring Request"
              :options="selectHiringRequestData"
              @option:selected="selectedOption"
            ></v-select>
            <template v-if="getHiringRequest">
              <div class="flex w-full my-5">
                <div
                  class="text-center text-grey bg-white rounded-xl ml-2 shadow-md"
                >
                  <p class="text-grey font-semibold text-lg mx-3">
                    {{ getHiringRequest.contract_type.toUpperCase() }}
                  </p>
                  <div class="relative">
                    <span
                      class="material-icons text-7xl mx-3"
                      :class="iconClass"
                    >
                      person
                    </span>
                  </div>
                </div>
                <div
                  class="bg-white rounded-xl w-full flex flex-col mx-2 justify-around shadow-md"
                >
                  <h3 class="font-bold text-lg pl-2">Request Information</h3>
                  <div class="flex pl-2">
                    <p>
                      Job title:
                      <span>{{ getHiringRequest.job_title }}</span>
                    </p>
                  </div>
                  <div class="flex pl-2">
                    <p>
                      Reports to:
                      {{ getHiringRequest.reporting_to }}
                    </p>
                  </div>
                </div>
              </div>
              <transition appear>
                <create-user
                  :mainClass="'w-full'"
                  :heightClass="'overflow-scroll max-h-5/6'"
                  @profile_created="showCreateUser = false"
                />
              </transition>
            </template>
          </div>
        </template>
        <template #successButton> Save </template>
      </base-modal>
      <base-modal
        v-model:show="showAllProfilesModal"
        @confirm="submit"
        :showCancel="false"
        :showConfirm="false"
      >
        <template #title>All Applicants Profiles</template>
        <template #content>
          <div class="p-5">
            <dropdown-search-filter
              title="Search Applicants Profiles"
              :mainFilter="mainFilter"
              :secondaryFilters="secondaryFilters"
              :inputPlaceHolder="'please input'"
              @search="searchFunc"
              @reset="applicantsProFilesFunc"
              @selectedMainFilter="mainFilterSelected"
            />
            <profileTable @pagination="applicantsProFilesFunc" />
          </div>
        </template>
        <template #successButton> Save </template>
      </base-modal>
    </div>
  </transition>
</template>
<script>
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import openVacanciesTableItem from "@/components/ui/baseComponents/Tables/RE/openVacanciesTableItem.vue";
import horizontalButtonTile from "@/components/ui/baseComponents/horizontalButtonTile.vue";
import dropdownSearchFilter from "@/components/ui/baseComponents/dropdownSearchFilter.vue";
import createUser from "@/components/ui/baseComponents/Recruitment/createUser.vue";
import profileTable from "./profileTable.vue";
export default {
  provide() {
    return { headers: this.tableHeaders };
  },
  async created() {
    await this.fetchAllHiringRequests("approved", this.page);
    this.$store.dispatch("admin/loadRoles");
    await this.applicantsProFilesFunc(1);
    await this.loadPractices();
  },
  components: {
    noDataFound,
    tableSkeleton,
    dynamicTable,
    openVacanciesTableItem,
    horizontalButtonTile,
    dropdownSearchFilter,
    createUser,
    profileTable,
  },
  data() {
    return {
      practiceData: [],
      dropdownPractices: [],
      filteredVacancies: [],
      refreshTable: 0,
      isLoading: false,
      page: 1,
      tableHeaders: [
        "Live",
        "Role",
        "Location",
        "Application Manager",
        "Applicants",
        "Manage Vacancy",
      ],
      options: {
        template: markRaw(customPaginate),
      },
      showCreateUser: false,
      showAllProfilesModal: false,
      mainFilter: [
        {
          label: "Role",
          value: "role",
          type: "dropdown",
        },
        {
          label: "Location",
          value: "location",
          type: "dropdown",
        },
        {
          label: "Hiring Request",
          value: "hiring_request",
          type: "dropdown",
        },
        {
          label: "First Name",
          value: "first_name",
          type: "input",
        },
        {
          label: "Last Name",
          value: "last_name",
          type: "input",
        },
        {
          label: "Email",
          value: "email",
          type: "input",
        },
      ],
      mainFilter2: [
        {
          label: "Role",
          value: "role",
          type: "dropdown",
        },
        {
          label: "Location",
          value: "location",
          type: "dropdown",
        },
        {
          label: "Manager",
          value: "manager",
          type: "input",
        },
        {
          label: "Live",
          value: "is_live",
          type: "dropdown",
        },
      ],
      secondaryFilters: [],
    };
  },
  methods: {
    mainFilterSelected({ value }) {
      if (value === "role") {
        this.secondaryFilters = this.getRoles;
        return;
      }
      if (value === "hiring_request") {
        this.secondaryFilters = this.selectHiringRequestData;
        return;
      }
      if (value === "location") {
        this.secondaryFilters = this.getPractices;
        return;
      }
      if (value === "is_live") {
        this.secondaryFilters = [
          {
            label: "No",
            value: 0,
          },
          {
            label: "Yes",
            value: 1,
          },
        ];
        return;
      }
    },
    async applicantsProFilesFunc(event) {
      await this.$store.dispatch("HQnewHire/filterProfiles", {
        page: event,
      });
      this.filteredVacancies = this.getApprovedHiringRequest;
    },
    async searchFunc({ filter, value }) {
      await this.$store.dispatch("HQnewHire/filterProfiles", {
        filter: filter.value,
        value: value.value ? value.value : value.code ? value.code : value,
      });
    },
    async searchFunc2({ filter, value }) {
      try {
        this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
          {
            status: "approved",
            [filter.value]: value.value
              ? value.value
              : value.code
              ? value.code
              : value,
          },
        ]);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async selectedOption(data) {
      this.$store.dispatch("HQnewHire/fetchHiringRequest", {
        hiring_request: data.value,
      });
    },
    async fetchAllHiringRequests(status, page) {
      try {
        this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
          {
            status: status,
            page,
          },
        ]);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadPractices() {
      try {
        await this.$store.dispatch("admin/loadPractices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    callFunction() {
      this.filteredVacancies = this.getFilteredVacancies;
    },
    change(page) {
      this.fetchAllHiringRequests("approved", page);
    },
  },

  computed: {
    getApprovedHiringRequest() {
      return this.$store.getters["HQnewHire/getApprovedHiringRequest"];
    },
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
    selectHiringRequestData() {
      return this.getApprovedHiringRequest?.data.map((item) => ({
        label: item?.job_title,
        value: item?.id,
      }));
    },
    getFilteredVacancies() {
      return this.$store.getters["HQnewHire/getVacanciesForFilter"];
    },
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },
    getPractices() {
      const data = this.$store.getters["admin/getPractices"];
      if (Object.keys(data).length !== 0) {
        data.forEach((item) => {
          this.practiceData.push({ code: item.id, label: item.practice_name });
        });
        return this.practiceData;
      } else {
        return this.practiceData;
      }
    },
  },
};
</script>
