<script setup>
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";
import TableData from "@/components/ui/MA/appraisals/TableData.vue";

const store = useStore();
const emit = defineEmits(["action"]);

const getCandidates = computed(() => {
  return store.getters["HQnewHire/getCandidates"];
});
const tabledropdownOptions = [
  {
    type: "link",
    linkName: "user-view-course",
    paramName: "id",
    label: "View",
  },
];

const tableHeader = [
  { title: "Name", prop: "profile.first_name", id: "name" },
  { title: "Role", prop: "roles[0].name", id: "first_name" },
  {
    title: "Hiring Request",
    prop: "profile.hiring_request.job_title",
    id: "hiring_request",
  },
  { title: "Type", prop: "profile.hiring_request.contract_type", id: "type" },
  // {
  //   title: "View More",
  //   prop: "user",
  //   id: "actions",
  // },
];

const change = (event) => {
  console.log(event, "event: ");
  emit("pagination", event);
};
</script>
<template>
  <div class="flex justify-center mt-5">
    <!-- OPEN Vacancies TITLE -->
    <div class="bg-lightGrey rounded-xl w-full shadow-md">
      <div class="pl-4 py-4">
        <h2 class="text-grey font-bold">Applicants Profile</h2>
      </div>
      <!-- <template v-if="!isLoading"> -->
      <div class="overflow-auto p-4" style="max-height: 300px">
        <template v-if="getCandidates">
          <TableData
            :dropdownOptions="tabledropdownOptions"
            :tableData="getCandidates?.data || []"
            :tableHeader="tableHeader"
            :rowClass="'text-center'"
          />
          <div v-if="getCandidates?.total > 0" class="flex justify-center my-5">
            <el-pagination
              background
              layout="pager"
              :total="getCandidates?.total"
              @current-change="change"
            >
            </el-pagination>
          </div>
        </template>
        <no-data-found v-else />
      </div>
    </div>
  </div>
</template>