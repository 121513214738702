<template>
  <tr
    v-for="item in data"
    :key="item.id"
    class="border-b hover:bg-orange-100 bg-gray-100 text-grey hover:bg-gray-200"
  >
    <td
      class="
        p-3
        px-5
        hover:scale-110
        transform-gpu
        transition
        duration-100
        ease-linear
      "
    >
      <template v-if="item.hiring_request_postings">
        <span
          v-if="isAddLive(item.hiring_request_postings)"
          class="material-icons text-lightGreen"
        >
          task_alt
        </span>
        <span v-else class="material-icons text-red"> highlight_off </span>
      </template>
      <template v-else>
        <span class="material-icons text-red"> highlight_off </span>
      </template>
    </td>

    <td class="p-3 px-5">
      <p v-if="item.job_title">{{ item.job_title }}</p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.practice?.practice_name">
        {{ item.practice?.practice_name }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item.application_manager">
        {{
          `${item.application_manager?.profile?.first_name} ${item.application_manager?.profile?.last_name}`
        }}
      </p>
    </td>
    <td class="p-3 px-5">
      <p v-if="item?.applicants">{{ item?.applicants.length }}</p>
    </td>
    <td
      class="
        p-3
        px-5
        cursor-pointer
        hover:scale-110
        transform-gpu
        transition
        duration-100
        ease-linear
      "
    >
      <router-link
        :to="{
          name: link_2,
          params: { [myParameter_2]: item.id },
        }"
      >
        <span class="material-icons"> more_horiz </span>
      </router-link>
    </td>
  </tr>
  <tr v-if="data?.length < 1" class="text-grey pt-2">
    <td class="flex justify-center">
   
  </td>
  <td></td>
  <td></td>
  <td> <span>No record found</span></td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    link_2: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    myParameter_2: {
      type: String,
    },
  },
  methods: {
    isAddLive(data) {
      return data.some((date) => moment(date.end_date) >= moment());
    },
  },
};
</script>
